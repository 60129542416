import { gql } from '@apollo/client';
import { platformFragments } from 'graphql/platformQueries';
import { fragments } from 'graphql/queries';

export const accommodationProviderFragments = {
  accommodationProvider: gql`
    fragment AccommodationProvider on AccommodationProvider {
      id
      type
      name
      disabled

      contactPerson
      email
      phoneNumber
      comment

      country
      zipCode
      city
      street

      locations {
        ...AccommodationProviderLocation
      }
    }
  `,

  accommodationProviderLocation: gql`
    fragment AccommodationProviderLocation on AccommodationProviderLocation {
      id
      providerId
      providerType
      disabled

      country
      zipCode
      city
      street

      latitude
      longitude

      distanceInMin
      description
      mapLink

      flats {
        ...AccommodationProviderFlat
      }
    }
  `,

  accommodationProviderFlat: gql`
    fragment AccommodationProviderFlat on AccommodationProviderFlat {
      id
      locationId
      providerType
      disabled

      floor
      name
      description
      descriptions {
        ...Translation
      }
      roomsNumber
      bathroomsNumber
      ac

      photos {
        ...File
      }

      orderNumber

      rooms {
        ...AccommodationProviderRoom
      }
    }
  `,

  accommodationProviderRoom: gql`
    fragment AccommodationProviderRoom on AccommodationProviderRoom {
      id
      flatId
      providerType
      disabled

      name
      description
      descriptions {
        ...Translation
      }
      sizeInSqm
      maxStudents
      roomTypeId

      bedsNumber
      bedType

      pricePerWeekSingle
      pricePerWeekShared
      providerPricePerWeek

      usageType
      gender

      ac
      bathroom
      balcony
      interior
      exterior
      hairdryer
      bedsheets
      wifi

      photos {
        ...File
      }

      orderNumber
    }
  `,
};

export const getAccommodationProviders = gql`
  query ($searchCriteria: AccommodationProviderSearchCriteria) {
    getAccommodationProviders(searchCriteria: $searchCriteria) {
      ...AccommodationProvider
    }
  }
  ${accommodationProviderFragments.accommodationProvider}
  ${accommodationProviderFragments.accommodationProviderLocation}
  ${accommodationProviderFragments.accommodationProviderFlat}
  ${accommodationProviderFragments.accommodationProviderRoom}
  ${platformFragments.file}
  ${fragments.translation}
`;

export const getAccommodationRooms = gql`
  query {
    getAccommodationRooms {
      ...AccommodationProviderRoom
    }
  }
  ${accommodationProviderFragments.accommodationProviderRoom}
  ${platformFragments.file}
  ${fragments.translation}
`;

export const getCleaningListPdf = gql`
  query ($date: String!, $providerType: AccommodationProviderType!) {
    getCleaningListPdf(date: $date, providerType: $providerType)
  }
`;

export const getPorterListPdf = gql`
  query ($date: String!, $providerType: AccommodationProviderType!) {
    getPorterListPdf(date: $date, providerType: $providerType)
  }
`;

export const getArrivalOrDepartureList = gql`
  query ($date: String!, $providerType: AccommodationProviderType!, $arrival: Boolean!) {
    getArrivalOrDepartureList(date: $date, providerType: $providerType, arrival: $arrival)
  }
`;

export const getBreakfastVoucherPdf = gql`
  query ($roomBookingId: Int!) {
    getBreakfastVoucherPdf(roomBookingId: $roomBookingId)
  }
`;

export const getDinnerVoucherPdf = gql`
  query ($roomBookingId: Int!) {
    getDinnerVoucherPdf(roomBookingId: $roomBookingId)
  }
`;
